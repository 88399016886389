import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { LoginData } from "./modal/login.modal";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
//import { ClientData } from './clientdata.modal';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
  })
export class AuthService {
    
    private authServiceListener = new Subject<any>();

    private accessToken: string = null;
    private sessionKey: string = null;
    private accessTokenExpireSecond: string = null;

    private isAuthenticated = null;
    //private clientData: ClientData = null;

    constructor(private http: HttpClient) {}

    getAuthServiceListener() {
        return this.authServiceListener.asObservable();
    }

    /*login(username: string, password: string){
        const loginData: LoginData = {username, password};
        this.http.post<any>(environment.apiUrl+'/client/login', loginData).subscribe((response) => {
            if(response.status === 1){
                this.accessToken = response.data[0].token;
                this.sessionKey = response.data[0].sessionkey;
                this.accessTokenExpireSecond = response.data[0].tokenexpiretime;
                this.clientData = response.data[0].user[0];

                localStorage.setItem('token', this.accessToken);
                localStorage.setItem('tokenexpiretime', moment().add(this.accessTokenExpireSecond, 'seconds').format());
                localStorage.setItem('client',JSON.stringify(response.data[0].user[0]));
                localStorage.setItem('session', this.sessionKey);
                this.setUserAuth(true);
            }
            this.authServiceListener.next(response);
        }, (error) => {
            console.log(error);
        });
    }*/

    resetpassword(username: string){
        const resetData: any = {username};
        console.log(resetData);
        this.http.patch(environment.apiUrl+'/client/login', resetData).subscribe((response) => {
            this.authServiceListener.next(response);
        }, (error) => {
            console.log(error);
        });
    }

    logoutour(){
        localStorage.setItem('isauth', String(false));
        this.isAuthenticated = false;
    }

    logout() {
        const headerDict = {
            'Authorization': 'Bearer '+this.getAccessToken(),
            'Authorization-Session': 'Bearer '+this.getSessionKey(),
          }
          
          const requestOptions = {                                                                                                                                                                                 
            headers: new HttpHeaders(headerDict), 
          };
        this.http.delete<any>(environment.apiUrl+'/client/login',requestOptions).subscribe((response) => {
            if(response.status === 1){
                this.accessToken = null;
                this.sessionKey = null;
                this.accessTokenExpireSecond = null;
                //this.clientData = null;

                localStorage.removeItem('token');
                localStorage.removeItem('tokenexpiretime');
                localStorage.removeItem('client');
                localStorage.removeItem('session');
                localStorage.removeItem('isauth');
                this.setUserAuth(false);
            }
            this.authServiceListener.next(response);
        }, (error) => {
            console.log(error);
        });
    }

    getAccessToken() {
        if(this.accessToken === null){
            this.accessToken = localStorage.getItem('token');   
        }
        return 'tadddaaaa';//this.accessToken;
    }

    setAccessToken(newToken: string, tokenExpireSecond: string) {
        localStorage.setItem('token',newToken);
        this.accessTokenExpireSecond = tokenExpireSecond;
        this.accessToken = localStorage.getItem('token');
        localStorage.setItem('tokenexpiretime', moment().add(this.accessTokenExpireSecond, 'seconds').format());  
    }

    getAccessTokenExpireSecond() {
        if(this.accessTokenExpireSecond === null){
            this.accessTokenExpireSecond = localStorage.getItem('tokenexpiretime');
        }
        return this.accessTokenExpireSecond;
    }

    getSessionKey() {
        if(this.sessionKey === null){
            this.sessionKey = localStorage.getItem('session');
        }
        return this.sessionKey;
    }

    /*getClientData() {
        if(this.clientData === null){
            this.clientData = JSON.parse(localStorage.getItem('client'));
        }
        return this.clientData;
    }*/

    /**
     * 
     * @param isAuth 
     *  Set Authentication flag
     */
    public setUserAuth(isAuth){
        localStorage.setItem('isauth', String(isAuth));
        this.isAuthenticated = isAuth;
    }

    isUserAuth(): boolean {
        
        if(this.isAuthenticated === null){
            return (localStorage.getItem('isauth') === 'true');
        } else {
            return this.isAuthenticated
        }        
    }
}