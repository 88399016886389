import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'Main',
        icon: 'mdi mdi-dots-horizontal',
        class: 'nav-small-cap',
        extralink: true,
        submenu: []
    },
    {
        path: '/dashboard/dashboard',
        title: 'Dashboards',
        icon: 'icon-Car-Wheel',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Match',
        icon: 'sl-icon-game-controller',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/match/list',
                title: 'Match List',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'Tournament',
        icon: 'sl-icon-trophy',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/tournament/runningtournament',
                title: 'Running Tournament',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/tournament/masterlist',
                title: 'Master List',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/tournament/mastercreate',
                title: 'Create Master',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'User',
        icon: 'sl-icon-user',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/user/list',
                title: 'User List',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            }
        ]
    },
];
