import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "./authservice.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate{
    constructor(private authService: AuthService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        //console.log('hajhsjahsh');
        const isAuth = this.authService.isUserAuth();
        console.log(isAuth);
        if(!isAuth){
            this.router.navigate(['/authentication/login']);
        }
        return isAuth; 
    }
}